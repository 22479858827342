import React from 'react'

const HeaderSocials = () => {
  return (
    <div className="home__socials">

        <a href="https://www.linkedin.com/in/liviabraz/" 
        className="home__social-link" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-linkedin"></i>
        </a>

        <a href="https://github.com/codingloria" 
        className="home__social-link" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-github"></i>
        </a>

        <a href="mailto:liviafariabraz282@gmail.com" 
        className="home__social-link" target="_blank" rel="noopener noreferrer">
            <i class="fa fa-envelope-o"></i>
        </a>

        <a href="https://www.instagram.com/codingloria_" 
        className="home__social-link" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-instagram"></i>
        </a>
    </div>
  )
}

export default HeaderSocials