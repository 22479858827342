import React, { useState } from 'react';
import "./projects.css";
import Menu from './Menu';

const Projects = () => {

  const [items, setItems] = useState(Menu);

  const filterItem = (categoryItem) => {
    const updatedItems = Menu.filter((curElem) => {
      return curElem.category.includes(categoryItem);
    });

    setItems(updatedItems);
  }

  return (
    <section className="work container section" id="projects">
      <h2 className="section__title"> Principais Projetos</h2>

      <div className="work__filters">
        <span className="work__item" onClick={() => setItems(Menu)}>Tudo</span>
        <span className="work__item" onClick={() => filterItem("React")}>React</span>
        <span className="work__item" onClick={() => filterItem("Angular")}>Angular</span>
        <span className="work__item" onClick={() => filterItem("Java")}>Java</span>
        <span className="work__item" onClick={() => filterItem("Python")}>Python</span>
        <span className="work__item" onClick={() => filterItem("Django")}>Django</span>
        <span className="work__item" onClick={() => filterItem("Integração BD")}>Integração BD</span>
        <span className="work__item" onClick={() => filterItem("Spring")}>Spring</span>
        <span className="work__item" onClick={() => filterItem("HTML - CSS- JS")}>HTML - CSS - JS</span>
      </div>

      <div className="work__container grid">
        {items.map((elem) => {
          const{ id, image, title, category, githubLink, liveLink } = elem;
          return (
            <div className="work__card" key={id}>
              <div className="work__thumbnail">
                <img src={image} alt="" className="work__img" />
                <div className="work__mask"></div>
              </div>

              <span className="work__category">{category.join(', ')}</span>
              <h3 className="work__title">{title}</h3>
              <div className="work__buttons">
                {githubLink && (
                  <a href={githubLink} className="work__button" target="_blank" rel="noopener noreferrer">
                    <i className="icon-social-github work__button-icon"></i>
                  </a>
                )}
                {liveLink && (
                  <a href={liveLink} className="work__button live-link" target="_blank" rel="noopener noreferrer">
                    <i className="icon-globe work__button-icon"></i>
                  </a>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Projects