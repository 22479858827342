import React from 'react'
import "./services.css";
import Image1 from "../../assets/back-end.png";
import Image2 from "../../assets/frontEnd.png";
import Image3 from "../../assets/fullstack.png";

const data = [
  {
    id: 1,
    image: Image1,
    title: "Back End",
    description:
      "▪️ Java; ▪️ Python; ",
  },
  {
    id: 2,
    image: Image2,
    title: "Front End",
    description:
      "▪️ HTML ▪️ CSS ▪️ JavaScript; ▪️ Angular; ▪️ React",
  },
  {
    id: 3,
    image: Image3,
    title: "Outras ferramentas",
    description:
      "▪️ SQL; ▪️ JIRA ▪️ Confluence; ▪️ Git ▪️ Bitbucket;",
  },
];


const Services = () => {

  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
  <section className="services container section" id="services">
    <h2 className="section__title">Tecnologias</h2>

    <div className="services__container grid">
      {data.map(({id, image, title, description}) => {
        return (
          <div className="services__card" key={id}>
            <img src={image} alt="" className="services__img" />
          
            <h3 className="services__title">{title}</h3>
            <div className="services__description">
                {description.split(';').map((line, index) => (
                  <p key={index} dangerouslySetInnerHTML={createMarkup(line.trim())}></p>
                ))}
              </div>
          </div>
        )
      })}
    </div>
  </section>
  );
}

export default Services