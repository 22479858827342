const Data = [
    {
      id: 1,
      category: "education",
      icon: "icon-graduation",
      year: "julho 2024 - em andamento",
      title: "Bacharelado em Engenharia de Software",
      desc: "Instituto INFNET; Formação prevista: junho 2028; Acompanhe meus estudos pelo repositório do GitHub, clicando <a href='https://github.com/codinGloria/engenhariaDeSoftware' target='_blank'>aqui</a>.",
  },
    {
      id: 2,
      category: "education",
      icon: "icon-graduation",
      year: "janeiro 2022 - junho 2024",
      title: "Tecnólogo em Análise e Desenvolvimento de Sistemas",
      desc: "Estácio;    ; Formação em linguagens de programação, banco de dados, desenvolvimento web, redes e segurança da informação. Habilidades para atuar como desenvolvedor e analista de sistemas.",
    },
    {
      id: 3,
      category: "experience",
      icon: "icon-briefcase",
      year: "abril 2024 - atualmente",
      title: "Estagiária em Governança de TI -> SPC Brasil",
      desc: "- Gerenciamento de mudanças e suporte às operações de TI usando RemedyForce;* - Elaboração e atualização de documentação para gestão de serviços; *- Apoio no atendimento a clientes internos e externos, assegurando satisfação e resolução eficiente das demandas.",
    },
    {
      id: 5,
      category: "experience",
      icon: "icon-briefcase",
      year: "julho 2022 - janeiro 2024",
      title: "Estagiária de Dados -> Bemobi",
      desc: "- Documentei procedimentos operacionais e projetos;* - Auxiliei em implementações, administração e monitoramento do banco de dados;* - Realizei ETL de diversas fontes de dados (PostgreSQL, SQL Server, Redshift, Spark) criando dashboards no Tableau e Looker;* - Criei e disponibilizei tabelas refinadas utilizando DAGs no Airflow.",
    },
];

export default Data