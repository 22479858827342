import React from 'react';
import "./about.css";
import Image from "../../assets/aa.jpg";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import AboutBox from './AboutBox';

ChartJS.register(ArcElement, Tooltip,Legend)

const About = () => {
  const data = {
    labels: ['Java', 'Angular', 'Python', 'React'],
    datasets: [
      {
        data: [40, 20, 20, 20],
        backgroundColor: ['#ff5063', '#4ed4b6', '#7171e6', '#ffd15c']
      }
    ]
  };

  return (
    <section className="about container section" id="about">
      <h2 className="section__title"> Sobre mim </h2>

      <div className="about__container grid">
        <img src={Image} alt="" className="about__img" />

        <div className="about__data grid">
          <div className="about__info">
            <p className="about__description">
            Oii Jóia ✨ Que bom te ter aqui! <br/>
            Meu nome é Lívia, mas podem me chamar de Glória, sou uma mineira de 22 anos apaixonada por tecnologia, animes, livros e gatinhos 🥰 
            <br/> <br/>
            Recém-graduada em Análise e Desenvolvimento de Sistemas, cursando Engenharia de Software na INFNET. 
            Possuo quase 2 anos de experiência em dados, atuando com SQL, Tableau, Looker, e metodologias ágeis como SCRUM e Kanban.
            <br/><br/> 
            Agora, focada em desenvolvimento, meu objetivo final é construir uma carreira de sucesso, buscando uma vida estável e feliz fazendo o que amo: codar \(*-*)/
            </p>
            <a href="https://drive.google.com/uc?export=download&id=1Z6Qfg-bdhBb65QLeiNKCxkv1Prf-hehe" className="btn">Baixar CV</a>
          </div>

          <div className="about__skills grid">
            <Pie
              data = {data}
              options= {{
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }}
            />
            <div className="legend-container">
              {data.labels.map((label, index) => (
                <div key={index} className="legend-badge">
                  <span className="legend-color" style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}></span>
                  <span className="legend-text">{label}</span>
                </div>
              ))}
            </div>
        </div>
      </div>
    </div>

      <AboutBox/>
    </section>
  )
}

export default About