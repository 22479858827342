import React, { useState } from 'react';
import "./sidebar.css";
import Logo from "../../assets/logo.png";

const Sidebar = () => {

  const [toggle, showMenu] = useState(false);

  return (
    <>
    <aside className={toggle ? "aside show-menu" : "aside"}>
        <div className="logo">
          <a href="#home" className="nav__logo">
            <img src={Logo} alt=""/>
          </a>
        </div>

        <nav className="nav">
          <div className="nav__menu">
            <ul className="nav__list">

              <li className="nav__item">
                <a href="#home" className="nav__link">
                  <i className="icon-home"></i>
                </a>
              </li>

              <li className="nav__item">
                <a href="#about" className="nav__link">
                  <i className="icon-user-female"></i>
                </a>
              </li>

              <li className="nav__item">
                <a href="#services" className="nav__link">
                  <i className="icon-screen-desktop"></i>
                </a>
              </li>

              <li className="nav__item">
                <a href="#resume" className="nav__link">
                  <i className="icon-graduation"></i>
                </a>
              </li>

              <li className="nav__item">
                <a href="#portfolio" className="nav__link">
                  <i className="icon-graph"></i>
                </a>
              </li>

              <li className="nav__item">
                <a href="#projects" className="nav__link">
                  <i className="icon-star"></i>
                </a>
              </li>

              <li className="nav__item">
                <a href="#blog" className="nav__link">
                  <i className="icon-layers"></i>
                </a>
              </li>

              <li className="nav__item">
                <a href="#contact" className="nav__link">
                  <i className="icon-bubble"></i>
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <div className="nav__footer">
          <span className="copyright">
            &copy; Lívia Braz - 2024
          </span>
        </div>
    </aside>

    <div className={toggle ? "nav__toggle nav__toggle-open" : 
      "nav__toggle"} onClick={() => showMenu(!toggle)}>
      <i className="icon-menu"></i>
    </div>
    </>
  );
};

export default Sidebar