import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import "./contact.css";

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    if (name === '' || email === '' || subject === '' || message === '') {
      alert("Preencha todos os campos");
      return;
    }

    const templateParams = {
      from_name: name,
      subject: subject,
      message: message,
      email: email
    };

    emailjs.send("service_aitks2a", "template_44csl9r", templateParams, "GbXotvx2dobaKP75T")
      .then((response) => {
        console.log("EMAIL ENVIADO", response.status, response.text);
        alert("Mensagem enviada com sucesso!");
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
      }, (error) => {
        console.log("ERRO: ", error);
        alert("Ocorreu um erro ao enviar a mensagem. Tente novamente.");
      });
  };

  return (
    <section className="contact container section" id='contact'>
      <h2 className="section__title">Entre em contato 🌹 </h2>
    
      <div className="contact__container grid">
        <div className="contact__info">
          <h3 className="contact__title"> Se tiver alguma pergunta ou apenas quiser bater um papo sobre meus projetos, sinta-se à vontade para me mandar uma mensagem! </h3>
        </div>

        <form className="contact__form" onSubmit={sendEmail}>
          <div className="contact__form-group">
            <div className="contact__form-div">
              <input type="text" className="contact__form-input" 
                onChange={(e) => setName(e.target.value)} 
                value={name}
                placeholder='Insira seu nome' required />
            </div>

            <div className="contact__form-div">
              <input type="email" className="contact__form-input" 
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder='Insira seu email' required />
            </div>
          </div>
          
          <div className="contact__form-div">
            <input type="text" className="contact__form-input"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              placeholder='Insira o assunto' required />
          </div>

          <div className="contact__form-div contact__form-area">
            <textarea name="message" cols="30" rows="10"
              className='contact__form-input' 
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              placeholder='Escreva sua mensagem' required></textarea>
          </div>

          <button type="submit" className="btn">Enviar mensagem</button>
        </form>
      </div>
    </section>
  );
};

export default Contact;