import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import logo from './assets/fotinha.jpeg';

document.title = "Lívia Braz";

const link = document.createElement('link');
link.rel = 'icon';
link.type = 'image/png';
link.href = logo; 
document.getElementsByTagName('head')[0].appendChild(link);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

