import React, { useRef, useEffect } from 'react';
import './timeline.css';
import Data from './Data'; // Importe os dados dos eventos

const Timeline = () => {
  const portfolioRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
    if (portfolioRef.current) {
      const portfolioWidth = portfolioRef.current.scrollWidth;
      lineRef.current.style.width = `${portfolioWidth}px`;
    }
  }, []);

  return (
    <section className="services container section" id='portfolio'>
      <h2 className="section__title">Timeline</h2>
      <div className="portfolio" ref={portfolioRef}>
        <div className="timeline-line" ref={lineRef}></div> 
        {Data.map((data, index) => (
          <div className="portfolio-item" key={index}>
            <div className="timeline-point"></div> 
            <div className="portfolio-item-box">
            <div className="portfolio-item-content">
              <div className="portfolio-item-date">{data.date}</div>
              <div className="portfolio-item-title">{data.title}</div>
              <div className="portfolio-item-description">
                {data.description}<br/>
                {data.technology}<br/>
                {data.link && <a href={data.link} target="_blank" rel="noopener noreferrer"> Ver Mais</a>}
              </div>
            </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Timeline;
