import React from 'react';
import "./blog.css";

import Image1 from '../../assets/post1.png';
import Image2 from '../../assets/post2.png';
import Image3 from '../../assets/post3.png';

const Blog = () => {
  return (
    <section className="blog container section" id="blog">
      <h2 className="section__title">Posts recentes</h2>

      <div className="blog__container grid">
        
      <div className="blog__card">
          <div className="blog__thumb">
            <span className="blog__category">Técnico</span>
            <a href="https://www.instagram.com/p/C-fGXPaus8v/"><img src={Image3} alt="" 
            className="blog__img" /></a>
          </div>
          <div className="blog__details">
            <h3 className="blog__title">Padrões de Commit</h3>
            <div className="blog__meta">
              <span>10 Agosto, 2024</span>
              <span className="blog__dot">.</span>
              <span>Glória</span>
            </div>
          </div>
        </div>


        <div className="blog__card">
          <div className="blog__thumb">
            <span className="blog__category">Livros</span>
            <a href="https://www.instagram.com/p/C-LAxBaxk2I/"><img src={Image2} alt="" 
            className="blog__img" /></a>
          </div>
          <div className="blog__details">
            <h3 className="blog__title">Livros Técnicos</h3>
            <div className="blog__meta">
              <span>02 Agosto, 2024</span>
              <span className="blog__dot">.</span>
              <span>Glória</span>
            </div>
          </div>
        </div>

        <div className="blog__card">
          <div className="blog__thumb">
            <span className="blog__category">Setup</span>
            <a href="https://www.instagram.com/p/C9-ANMOxAti/"><img src={Image1} alt="" 
            className="blog__img" /></a>
          </div>
          <div className="blog__details">
            <h3 className="blog__title">Apresentação Inicial</h3>
            <div className="blog__meta">
              <span>28 Julho, 2024</span>
              <span className="blog__dot">.</span>
              <span>Glória</span>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Blog